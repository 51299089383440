// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import '_variables_effektivstad.scss';

// Layout
@import '_layout_effektivstad.scss';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



/*
|--------------------------------------------------------------------------
| Expired Page
|--------------------------------------------------------------------------
*/

@media screen and (min-resolution: 120dpi) {
body {zoom: 0.8;}
}

.expired-text {
  margin-top: 100px;

  font-size: 1.2rem;

  a {
    color: $black;
    text-decoration: underline;
  }
}

.card-text.price {
  font-size: 1.3rem !important;
}

.card-subtitle {
    font-size: 1.2rem;
}

.card-text {
    font-size: 1rem;
}
