body {
    background: $body-bg !important;
    font-family: $font-family-sans-serif !important;
}

.card {
    background-color: $white !important;
    border: none !important;
    color: $black;
    a {
        color: $black;
        text-decoration: underline;
        &:hover {
            color: rgba(0,0,0,0.75);
        }
    }
    .btn-primary {
        display: inline-block !important;
        background-color: $blue !important;
        border: 1px solid $blue;
        color: $white !important;
        float: right;
        width: 200px !important;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 15px 0;
        transition: all 0.4s ease;
        &:hover {
            background-color: $button-bg !important;
            color: $white !important;
            transition: all 0.4s ease;
        }
    }
}

body .btn-primary:focus, .btn-primary.focus {
    background-color: $button-bg !important;
    color: $white !important;
    transition: all 0.4s ease;
}
body .btn-primary:active, .btn-primary.active {
    background-color: $button-bg !important;
    color: $white !important;
    transition: all 0.4s ease;
}

.form-bg {
    min-width: 800px !important;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    padding: 40px;
}

.subpages .card-body {
    background: $white !important;
    color: $black;
    .btn-primary {
        background: $blue !important;
        color: $white !important;
    }
}

.container.additional-content-padding {
    padding: 40px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
}

.back-button {
    margin-right: 15px;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn {
    width: 32px !important;
    height: 32px !important;
    font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0;
}

.rounded-circle.active.btn-primary {
    float: none !important;
}

.card a.summary-link {
    color: $black !important;
}

body button.login {
    display: inline-block !important;
    background-color: #36B0E5 !important;
    border: 1px solid #36B0E5 !important;
    color: #ffffff !important;
    float: none !important;
    width: 100% !important;
    margin: 10px auto;
    border-radius: 26px;
    padding: 15px 0;
    transition: all 0.4s ease;
}

.buttons-desktop {
	display: block;
}

.buttons-mobile {
	display: none;
}


@media screen and (max-width:766px) {

	.buttons-desktop {
	    display: none;
	}

	.buttons-mobile {
	    display: block;
	}

			.card .btn-primary {
			    display: block !important;
			    background-color: #3190CC !important;
			    border: 1px solid #3190CC;
			    color: #fff !important;
			    float: none;
			    width: 100% !important;
			    border-radius: 5px;
			    padding: 15px 0;
			    transition: all 0.4s ease;
					margin: 20px 0;
			}

}
